.page-title {
    font-size: 45px !important;
    font-weight: 900 !important;
    text-align: center !important;
}
.publishing-date {
    text-align: right !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    margin-right: 11px !important;
}
.news-title {
    position: absolute;
    margin: 20px !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: white !important;
}
.news-author {
    position: absolute !important;
    right: 0 !important;
    bottom: 0 !important;
    padding-bottom: 11px !important;
    margin: 16px !important;
}
.card-news {
    height: 500px !important;
}
.descripcion {
    height: 254px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: wrap;
}