.trading-view {
  min-height: 92vh !important;
  height: 92vh !important;
}
.minimized-modal {
  height: 72px !important;
}
.market-modal {
  height: 380px !important;
}
.limit-modal {
  height: 440px !important;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-back-button {
  z-index: 1;
}

.header-minimize-button {
  z-index: 1;
}
.order-type-container {
  display: flex;
  justify-content: space-evenly;
  gap: 4px;
}
.side-button {
  border-radius: 54px !important;
  min-height: 24px !important;
  padding: 0px !important;
  font-size: 11px !important;
  max-width: 114px;
}
.order-type-button {
  border-radius: 54px !important;
  min-height: 24px !important;
  padding: 0px !important;
  font-size: 10px !important;
  max-width: 96px;
}

.amount-input {
  width: 100%;
  height: 28px;
  padding: 10px;
  border-radius: 28px !important;
}

.amount-price {
  width: 100%;
  height: 28px;
  padding: 10px;
  border-radius: 28px !important;
}

.pointer {
  cursor: pointer;
}
