.crypto-pair-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .name {
        margin-left: 8px;
        margin-top: 4px;
        font-weight: 700 !important;
    }
}