.cp-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
        width: 28px;
        height: 28px;
    }

    .name {
        margin-left: 8px;
        margin-top: 4px;
        font-weight: 700;
    }

    @media (max-width:767.95px) {
        justify-content: flex-start;
    }
}