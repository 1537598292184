.selected {
    background-color: black !important;
    color: white !important;
    font-weight: 900 !important;
}
.notSelected {
    color: gray !important;
    font-weight: 900 !important;
}
.refresh-button {
    margin-right: 14px !important;
}
.refresh-icon {
    font-size: 24px;
    margin-right: 6px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.title-wallet {
    font-size: 28px !important;
    font-weight: 500 !important;
    display: flex;
    align-items: center;
}
.subtitle {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: gray !important;
    display: block !important;
    margin-bottom: 28px !important;
}
.exchanges {
    width: 245px !important;
    max-height: 28px !important;
    min-height: 28px !important;
    position: absolute !important;
    right: 0 !important;
    margin-right: 45px !important;
}
.exchanges-button-text {
    font-size: 16px;
}
.crypto-market-name {
    margin-left: 11px !important;
    vertical-align: top !important;
    font-weight: 700 !important;
}
.crypto-from-exchange {
    font-weight: 700 !important;
    font-size: 14px !important;
}
.crypto-from-quantity {
    font-weight: 500 !important;
    font-size: 12px !important;
}
.crypto-label {
    font-size: 12px !important;
    font-weight: 500 !important;
    color: gray !important;
    display: inline !important;
    padding: 0px !important;
}
.crypto-label-qty-min {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-right: 11px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
}
.crypto-label-qty-max {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-top: 0px !important;
    padding: 0px !important;
}
.crypto-qty {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-left: 64px !important;
    margin-bottom: 0px !important;
    padding: 0px !important;
}
.crypto-symbol {
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-left: 64px !important;
    color: gray !important;
    margin-top: 0px !important;
    padding: 0px !important;
}
.crypto-profit {
    font-size: 12px !important;
    font-weight: 700 !important;
    color: black !important;
    margin-top: 9px !important;
    margin-left: 45px !important;
    padding: 0px !important;
}
.crypto-close-button {
    margin-left: 28px !important;
    width: 100px !important;
    border-radius: 54px !important;
    min-height: 28px !important;
    max-height: 28px !important;
}
.crypto-view-button {
    margin-left: 45px !important;
    border-radius: 54px !important;
    width: 28px !important;
    min-height: 20px !important;
    max-height: 20px !important;
}
.crypto-exchange-keys {
    margin-left: 45px !important;
    border-radius: 54px !important;
    width: 254px !important;
    min-height: 20px !important;
    max-height: 20px !important;
}
.labels {
    display: inline-block !important;
}
.crypto-name {
    width: 128px;
}
.column {
    margin-right: 5px !important;
}
.row {
    padding-bottom: 11px !important;
    border-bottom: 1px solid #ddd !important;
}
.percentage {
    width: 45px !important;
    height: 20px !important;
    overflow: hidden !important;
    display: inline-block;
}
.price {
    width: 72px !important;
}
.big-number {
    width: 90px !important;
    height: 20px !important;
    overflow: hidden !important;
}
.title-card {
    display: inline-block !important;
    font-size: 28px !important;
    font-weight: 900 !important;
    position: absolute !important;
    top: 20px;
    margin-left: 11px !important;
}
.amount-card {
    font-size: 18px !important;
    font-weight: 700 !important;
}
.amount-crypto, .amount-fiat {
    font-weight: 400 !important;
}
.amount-locked {
    font-weight: 400 !important;
    font-size: 14px !important;
}
.symbol {
    display: inline-block !important;
}