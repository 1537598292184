.title-streaming {
  border-radius: 54px !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-bottom: 16px !important;
  z-index: 1000 !important;
}

.flexible-modal {
  max-height: fit-content !important;
  position: absolute !important;
  z-index: 10000 !important;
  background: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(9px);
  border-radius: 1rem;
  -webkit-box-shadow: 12px 22px 52px -15px rgba(0, 0, 0, 0.54);
  -moz-box-shadow: 12px 22px 52px -15px rgba(0, 0, 0, 0.54);
  box-shadow: 12px 22px 52px -20px rgba(0, 0, 0, 0.28);
}

.flexible-modal-mask {
  position: fixed !important;
  height: 100% !important;
  background: transparent !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000 !important;
}

.flexible-modal-resizer {
  width: 15px !important;
  height: 15px !important;
  z-index: 100000 !important;
  position: absolute !important;
  right: 10px !important;
  bottom: 9px !important;
  cursor: se-resize !important;
  border-top: solid 0px #fff !important;
  border-right: solid 3px #fff !important;
  border-bottom: solid 3px #fff !important;
}

.flexible-modal-drag-area {
  background: rgba(22, 22, 333, 0) !important;
  border-radius: 1rem;
  height: 3rem;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

.video {
  border-bottom-right-radius: 28px !important;
  border-bottom-left-radius: 28px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
}

.modal-resizer {
  position: fixed !important;
  z-index: 99999 !important;
  /* padding: 1vh; */
}

.flexible-modal-drag-area-right,
.flexible-modal-drag-area-left {
  height: 0px !important;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}